<template>
    <div class="register-box">
        <div class="card">
            <div class="card-body register-card-body">
                <p class="login-box-msg">Reset Password</p>
                <div :class="alertStatus" v-if="showMessage">{{ textMessage }}</div>
                <form @submit="submitForm" method="post">
                    <div class="input-group mt-3">
                        <input :disabled="disabled" type="password" class="form-control" ref="pass" minlength="8" required v-model="form.pass" placeholder="Sandi">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mt-3">
                        <input :disabled="disabled" type="password" class="form-control" ref="pass2" required v-model="form.pass2" placeholder="Ulangi Sandi">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <!-- /.col -->
                        <div class="col-12">
                            <button  :disabled="disabled" type="submit" class="btn btn-primary btn-block">Ganti Password</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>
                <br>
                <br>
                <div class="text-center">
                    <router-link to="/login" class="text-center">Kembali ke halaman login</router-link>
                </div>
            </div>
            <!-- /.form-box -->
        </div><!-- /.card -->
    </div>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';

import $ from 'jquery';
export default {
    name: 'ResetPassword',
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            disabled: false,
            groups: [],
            showMessage: false,
            textMessage: '',
            alertStatus: '',
            form: {
                id: '',
                key: '',
                pass: '',
                pass2: ''
            }
        }
    },
    computed: {},
    created: function() {
        $('body').addClass('register-page');
        $('body').removeAttr('style');
        this.form.id = this.$route.params.id;
        if(this.form.id)
            this.checkData(this.form.id);
    },
    mounted() {

    },
    beforeDestroy() {
        $('body').removeClass('register-page');
    },
    methods: {
        checkData: function(id) {
            fetch(window.$apiUrl + '/users/register/resetpass/'+id)
            .then(res => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
            })
            .then(js => {
                if(js.status){
                    this.form.key = js.data.key;
                }
                else{                    
                    this.textMessage = js.message;
                    this.showMessage = true;
                    this.alertStatus = 'alert alert-danger';
                    this.disabled = true;
                }
              
            });
        },
        resetForm: function() {
            for (const key in this.$refs) {
                this.$refs[key].setAttribute('title', '');
                this.$refs[key].classList.remove('is-invalid');
            }
        },
        submitForm: function(e) {
            this.resetForm();
            this.showMessage = false;
            if (this.form.pass != this.form.pass2) {
                this.showMessage = true;
                this.textMessage = 'Kedua password tidak sama';
                this.alertStatus = 'alert alert-danger';
                e.preventDefault();
                return;
            }
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            fetch(window.$apiUrl + '/users/register/resetpass', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {} else if (res.status === 400) {
                        //let errorResponse = await res.json();
                        //this.errors.push(errorResponse.error);
                    }
                    return res.json();
                })
                .then(js => {
                    if (js.status) {
                        this.textMessage = js.message; //'Registrasi berhasil. Silahkan periksa email untuk verifikasi akun.';
                        this.showMessage = true;
                        this.alertStatus = 'alert alert-success';
                        this.form.pass = '';
                        this.form.pass2 = '';
                        this.disabled = true;
                    } else if (!js.status) {
                        if (js.type == 'error_input') {
                            for (const key in js.details) {
                                this.$refs[key].setAttribute('title', js.details[key]);
                                this.$refs[key].classList.add('is-invalid');
                            }
                        } else {
                            this.textMessage = js.message;
                            this.showMessage = true;
                            this.alertStatus = 'alert alert-danger';
                        }
                    }
                    console.log(js)
                });
            e.preventDefault();
        },
    }
}
</script>